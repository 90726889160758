import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Tab,Tabs,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Modal,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class BankingInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      merchant_id:null,
 bank_name:"",
 bank_address:"",
 routing_aba_number:"",
 swift_bic:"",
 account_number:"",
 beneficiary_name:"",
 benefiary_address:"",
 intermediary_bank:"",
 intermediary_bank_address:"",
 intermediary_swift_bic_code:"",
 bank_nameError: null,
 bank_addressError: null,
 bank_address_cadError: null,
 bank_address_eurError: null,
 bank_address_gbpError: null,
 routing_aba_numberError: null,
 swift_bicError: null,
 account_numberError: null,
 beneficiary_nameError: null,
 benefiary_addressError: null,
 intermediary_bankError: null,
 intermediary_bank_addressError: null,
 intermediary_swift_bic_codeError: null,
 bank_name_cad:"",
 bank_address_cad:"",
 routing_aba_number_cad:"",
 swift_bic_cad:"",
 account_number_cad:"",
 beneficiary_name_cad:"",
 benefiary_address_cad:"",
 intermediary_bank_cad:"",
 intermediary_bank_address_cad:"",
 intermediary_swift_bic_code_cad:"",
 transit_cad:"",
 transit:"",
 institution_cad:"",
 bank_name_cadError: null,
 routing_aba_number_cadError: null,
 swift_bic_cadError: null,
 account_number_cadError: null,
 beneficiary_name_cadError: null,
 benefiary_address_cadError: null,
 intermediary_bank_cadError: null,
 intermediary_bank_address_cadError: null,
 intermediary_swift_bic_code_cadError: null,
 bank_name_eur:"",
 bank_address_eur:"",
 routing_aba_number_eur:"",
 swift_bic_eur:"",
 account_number_eur:"",
 beneficiary_name_eur:"",
 benefiary_address_eur:"",
 intermediary_bank_eur:"",
 intermediary_bank_address_eur:"",
 intermediary_swift_bic_code_eur:"",
 iban_eur:"",
 bank_name_gbp:"",
 bank_address_gbp:"",
 routing_aba_number_gbp:"",
 swift_bic_gbp:"",
 account_number_gbp:"",
 beneficiary_name_gbp:"",
 benefiary_address_gbp:"",
 intermediary_bank_gbp:"",
 intermediary_bank_address_gbp:"",
 intermediary_swift_bic_code_gbp:"",
 iban_gbp:"",
 btc_address:"",
 bch_address:"",
 ltc_address:"",
 eth_address:"",
 doge_address:"",
 usdc_address:"",
 xrp_address:"",
 xrp_destination_tag:"",
 sol_address:"",
 dash_address:"",
 usdt_tron_address:"",
 bnb_address:"",
 ada_address:"",
 avax_address:"",
 mlp_address:"",
tsuka_address:"",
 shib_address:"",
 matic_poly_address:"",
 usdt_address:"",
 refmemo_usd:"",
 refmemo_cad:"",
 refmemo_eur:"",
 bank_name_eurError: null,
 routing_aba_number_eurError: null,
 swift_bic_eurError: null,
 account_number_eurError: null,
 beneficiary_name_eurError: null,
 benefiary_address_eurError: null,
 intermediary_bank_eurError: null,
 intermediary_bank_address_eurError: null,
 intermediary_swift_bic_code_eurError: null,
 refmemo_gbp:"",
 bank_name_gbpError: null,
 routing_aba_number_gbpError: null,
 swift_bic_gbpError: null,
 account_number_gbpError: null,
 beneficiary_name_gbpError: null,
 benefiary_address_gbpError: null,
 intermediary_bank_gbpError: null,
 intermediary_bank_address_gbpError: null,
 intermediary_swift_bic_code_gbpError: null,
 refmemo_eurError: null,
 refmemo_gbpError: null,
 refmemo_cadError: null,
 refmemo_usdError: null,
 etransfer_email:"",
 etransfer_password:"",
 showModal:false,
 showVerify:false,
 twofa_verify:false,
 isSubmitted:false,
 verifycode:"",
 verifycodeError:null,


      
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleValidate = this.handleValidate.bind(this);
    this.handleValidateCode = this.handleValidateCode.bind(this);
 
  }

  componentWillMount(){

    this.getmerchantinfo();


  }

  getmerchantinfo(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':user.data.merchant_id,'auid': auid },
    };

    fetch(Config.API+'/api/banking/',requestOptions)
    .then( this.handleResponse)
     .then( banking=> this.setState({bank_name:banking.data.bank_name,
      bank_address:banking.data.bank_address,
        routing_aba_number:banking.data.routing_aba_number,
        swift_bic:banking.data.swift_bic,
        account_number:banking.data.account_number,
        beneficiary_name:banking.data.beneficiary_name,
        benefiary_address:banking.data.benefiary_address,
        intermediary_bank:banking.data.intermediary_bank,
        intermediary_bank_address:banking.data.intermediary_bank_address,
        intermediary_swift_bic_code:banking.data.intermediary_swift_bic_code,
        bank_name_cad:banking.data.bank_name_cad,
        bank_address_cad:banking.data.bank_address_cad,
        routing_aba_number_cad:banking.data.routing_aba_number_cad,
        swift_bic_cad:banking.data.swift_bic_cad,
        account_number_cad:banking.data.account_number_cad,
        beneficiary_name_cad:banking.data.beneficiary_name_cad,
        benefiary_address_cad:banking.data.benefiary_address_cad,
        intermediary_bank_cad:banking.data.intermediary_bank_cad,
        intermediary_bank_address_cad:banking.data.intermediary_bank_address_cad,
        intermediary_swift_bic_code_cad:banking.data.intermediary_swift_bic_code_cad,
        bank_name_eur:banking.data.bank_name_eur,
        bank_address_eur:banking.data.bank_address_eur,
        routing_aba_number_eur:banking.data.routing_aba_number_eur,
        swift_bic_eur:banking.data.swift_bic_eur,
        account_number_eur:banking.data.account_number_eur,
        beneficiary_name_eur:banking.data.beneficiary_name_eur,
        benefiary_address_eur:banking.data.benefiary_address_eur,
        intermediary_bank_eur:banking.data.intermediary_bank_eur,
        intermediary_bank_address_eur:banking.data.intermediary_bank_address_eur,
        intermediary_swift_bic_code_eur:banking.data.intermediary_swift_bic_code_eur,  btc_address:banking.data.btc_address,
        bch_address:banking.data.bch_address,ltc_address:banking.data.ltc_address,eth_address:banking.data.eth_address,
        transit_cad:banking.data.transit_cad, transit:banking.data.transit,
        institution_cad:banking.data.institution_cad,
        iban_eur:banking.data.iban_eur,
        etransfer_email:banking.data.etransfer_email,
        etransfer_password:banking.data.etransfer_password,
        doge_address:banking.data.doge_address,
        usdt_address:banking.data.usdt_address,
        usdc_address:banking.data.usdc_address,
        xrp_address:banking.data.xrp_address,
        xrp_destination_tag:banking.data.xrp_destination_tag,
        sol_address:banking.data.sol_address,
        dash_address:banking.data.dash_address,
        usdt_tron_address:banking.data.usdt_tron_address,
        bnb_address:banking.data.bnb_address,
        ada_address:banking.data.ada_address,
        avax_address:banking.data.avax_address,
        shib_address:banking.data.shib_address,
        mlp_address:banking.data.mlp_address,
        tsuka_address:banking.data.tsuka_address,
        matic_poly_address:banking.data.matic_poly_address,
        refmemo_usd:banking.data.refmemo_usd,
        refmemo_cad:banking.data.refmemo_cad,
        refmemo_eur:banking.data.refmemo_eur,
        bank_name_gbp:banking.data.bank_name_gbp,
        bank_address_gbp:banking.data.bank_address_gbp,
        routing_aba_number_gbp:banking.data.routing_aba_number_gbp,
        swift_bic_gbp:banking.data.swift_bic_gbp,
        account_number_gbp:banking.data.account_number_gbp,
        beneficiary_name_gbp:banking.data.beneficiary_name_gbp,
        benefiary_address_gbp:banking.data.benefiary_address_gbp,
        intermediary_bank_gbp:banking.data.intermediary_bank_gbp,
        intermediary_bank_address_gbp:banking.data.intermediary_bank_address_gbp,
        intermediary_swift_bic_code_gbp:banking.data.intermediary_swift_bic_code_gbp,
        iban_gbp:banking.data.iban_gbp,
        refmemo_gbp:banking.data.refmemo_gbp,
        loading: false}));

     
     })
    }

    
    updatemerchantinfo(bank_name,bank_address,routing_aba_number,swift_bic,account_number,beneficiary_name,benefiary_address,intermediary_bank,intermediary_bank_address,intermediary_swift_bic_code,bank_name_cad,bank_address_cad,routing_aba_number_cad,swift_bic_cad,account_number_cad,beneficiary_name_cad,benefiary_address_cad,intermediary_bank_cad,intermediary_bank_address_cad,intermediary_swift_bic_code_cad,bank_name_eur,bank_address_eur,routing_aba_number_eur,swift_bic_eur,account_number_eur,beneficiary_name_eur,benefiary_address_eur,intermediary_bank_eur,intermediary_bank_address_eur,intermediary_swift_bic_code_eur,btc_address,bch_address,ltc_address,eth_address,transit_cad,transit,institution_cad,iban_eur,etransfer_email,etransfer_password,doge_address,usdt_address,refmemo_usd,refmemo_cad,refmemo_eur,usdc_address,xrp_address,xrp_destination_tag,sol_address,dash_address,usdt_tron_address,bnb_address,mlp_address,ada_address,avax_address,shib_address,matic_poly_address,tsuka_address,bank_name_gbp,bank_address_gbp,routing_aba_number_gbp,swift_bic_gbp,account_number_gbp,beneficiary_name_gbp,benefiary_address_gbp,intermediary_bank_gbp,intermediary_bank_address_gbp,intermediary_swift_bic_code_gbp,iban_gbp,refmemo_gbp){

      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
      var mid=user.data.merchant_id;
var token=user.token;
var auid=user.data.user_id;
var verifycode=this.state.verifycode
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'  ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
         body: JSON.stringify({ bank_name,bank_address,routing_aba_number,swift_bic,account_number,beneficiary_name,benefiary_address,intermediary_bank,intermediary_bank_address,intermediary_swift_bic_code,bank_name_cad,bank_address_cad,routing_aba_number_cad,swift_bic_cad,account_number_cad,beneficiary_name_cad,benefiary_address_cad,intermediary_bank_cad,intermediary_bank_address_cad,intermediary_swift_bic_code_cad,bank_name_eur,bank_address_eur,routing_aba_number_eur,swift_bic_eur,account_number_eur,beneficiary_name_eur,benefiary_address_eur,intermediary_bank_eur,intermediary_bank_address_eur,intermediary_swift_bic_code_eur,btc_address,ltc_address,bch_address,eth_address,transit_cad,institution_cad,iban_eur,etransfer_email,etransfer_password,transit,doge_address,usdt_address,refmemo_usd,refmemo_cad,refmemo_eur,usdc_address,xrp_address,xrp_destination_tag,sol_address,dash_address,usdt_tron_address,bnb_address,mlp_address,ada_address,avax_address,shib_address,matic_poly_address,tsuka_address,bank_name_gbp,bank_address_gbp,routing_aba_number_gbp,swift_bic_gbp,account_number_gbp,beneficiary_name_gbp,benefiary_address_gbp,intermediary_bank_gbp,intermediary_bank_address_gbp,intermediary_swift_bic_code_gbp,iban_gbp,refmemo_gbp,verifycode})
      };
          
    
  return fetch(Config.API+`/api/banking/update`,requestOptions)
     .then( this.handleRequestResponse)
     .then( user =>  {if (user.status=='success') {
      this.setState({showModal:false, verifycode:""},this.props.handleClick("tr","success","Account info has been updated",<span data-notify='icon' className='pe-7s-check' />))
    } else{  this.setState({verifycodeError:(<small className="text-danger">{user.message}</small>)})}
    })
  })}

  handleRequestResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
  
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
  
          if(data.status=='error'){
            if(data.type=='auth'){
           
  
  return data
            }else if(data.data.user_id==0) {
              return data
            }else{
  
                 userService.logout()
               return 'error'
            }
            
           
  
          }else{return data;}
        
  
        }
       
  
        
    });
  }
    handleCreateResponse(response) {
  
      return response.text().then(text => {
          const data = text && JSON.parse(text);
    
          return data;
      });
    }
      handleResponse(response) {
    
        return response.text().then(text => {
            const data = text && JSON.parse(text);
     
            if (!response.ok) {
                
             // const error = (data && data.message);
              return 'error'
            }else{
            //  console.log(data.status)
              if(data.status=='error'){
                userService.logout()
                
                   return 'error'
              }
            
    
            }
           
    
            return data;
        });
    }
  

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }



  handleValidateCode(){


    this.state.verifycode.length<= 5
    ? this.setState({
      verifycodeError: (
        <small className="text-danger"></small>
        ),verifydisable:true
    })
        : isNaN(this.state.verifycode)
        ?  this.setState({
          verifycodeError: (
          <small className="text-danger">Code must be numeric</small>
          ),verifydisable:true
          })
        : this.setState({ verifycodeError: null ,verifydisable:false,isSubmitted:false});
  
  }
  
  
  handleValidate(e) {

    const { name, value } = e.target;
    this.setState({ [name]: value });
    
    

  }

  request2fa(){

    userService.getinfo().then((data)=>{
  
      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var token=user.token;
    var auid=user.data.user_id;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid,'auid': auid}
  };
  
  fetch(Config.API+`/api/authenticate`, requestOptions)
    .then(this.handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
  this.setState({  twofa_verify:user.data.twofa_enabled})

          
      }
    })
  
  })
  }
  handleSubmit(e) {
    e.preventDefault();
    this.setState({showVerify:true,showModal:true,verifycode:""},function(){
      this.request2fa()
      })
   }



  handleSubmitVerify() {
  
    const { bank_name,bank_address,routing_aba_number,swift_bic,account_number,beneficiary_name,benefiary_address,intermediary_bank,intermediary_bank_address,intermediary_swift_bic_code,bank_name_cad,bank_address_cad,routing_aba_number_cad,swift_bic_cad,account_number_cad,beneficiary_name_cad,benefiary_address_cad,intermediary_bank_cad,intermediary_bank_address_cad,intermediary_swift_bic_code_cad,bank_name_eur,bank_address_eur,routing_aba_number_eur,swift_bic_eur,account_number_eur,beneficiary_name_eur,benefiary_address_eur,intermediary_bank_eur,intermediary_bank_address_eur,intermediary_swift_bic_code_eur,btc_address,bch_address,ltc_address,eth_address,transit_cad,transit,institution_cad,iban_eur,etransfer_email,etransfer_password,doge_address,usdt_address,refmemo_usd,refmemo_cad,refmemo_eur,usdc_address,xrp_address,xrp_destination_tag,sol_address,dash_address,usdt_tron_address,bnb_address,mlp_address,ada_address,avax_address,shib_address,matic_poly_address,tsuka_address,bank_name_gbp,bank_address_gbp,routing_aba_number_gbp,swift_bic_gbp,account_number_gbp,beneficiary_name_gbp,benefiary_address_gbp,intermediary_bank_gbp,intermediary_bank_address_gbp,intermediary_swift_bic_code_gbp,iban_gbp,refmemo_gbp
    } = this.state;
   if( this.state.bank_nameError === null ){
  this.updatemerchantinfo(bank_name,bank_address,routing_aba_number,swift_bic,account_number,beneficiary_name,benefiary_address,intermediary_bank,intermediary_bank_address,intermediary_swift_bic_code,bank_name_cad,bank_address_cad,routing_aba_number_cad,swift_bic_cad,account_number_cad,beneficiary_name_cad,benefiary_address_cad,intermediary_bank_cad,intermediary_bank_address_cad,intermediary_swift_bic_code_cad,bank_name_eur,bank_address_eur,routing_aba_number_eur,swift_bic_eur,account_number_eur,beneficiary_name_eur,benefiary_address_eur,intermediary_bank_eur,intermediary_bank_address_eur,intermediary_swift_bic_code_eur,btc_address,bch_address,ltc_address,eth_address,transit_cad,transit,institution_cad,iban_eur,etransfer_email,etransfer_password,doge_address,usdt_address,refmemo_usd,refmemo_cad,refmemo_eur,usdc_address,xrp_address,xrp_destination_tag,sol_address,dash_address,usdt_tron_address,bnb_address,mlp_address,ada_address,avax_address,shib_address,matic_poly_address,tsuka_address,bank_name_gbp,bank_address_gbp,routing_aba_number_gbp,swift_bic_gbp,account_number_gbp,beneficiary_name_gbp,benefiary_address_gbp,intermediary_bank_gbp,intermediary_bank_address_gbp,intermediary_swift_bic_code_gbp,iban_gbp,refmemo_gbp
    )}

   }
  render() {
    const { isSubmitted,bank_name,bank_address,routing_aba_number,swift_bic,account_number,beneficiary_name,benefiary_address,intermediary_bank,intermediary_bank_address,intermediary_swift_bic_code,bank_name_cad,bank_address_cad,routing_aba_number_cad,swift_bic_cad,account_number_cad,beneficiary_name_cad,benefiary_address_cad,intermediary_bank_cad,intermediary_bank_address_cad,intermediary_swift_bic_code_cad,bank_name_eur,bank_address_eur,routing_aba_number_eur,swift_bic_eur,account_number_eur,beneficiary_name_eur,benefiary_address_eur,intermediary_bank_eur,intermediary_bank_address_eur,intermediary_swift_bic_code_eur,btc_address,bch_address,ltc_address,eth_address,transit_cad,transit,institution_cad,iban_eur,etransfer_email,etransfer_password,loading,doge_address,usdt_address,refmemo_usd,refmemo_cad,refmemo_eur,usdc_address,xrp_address,xrp_destination_tag,sol_address,dash_address,usdt_tron_address,bnb_address,mlp_address,ada_address,avax_address,shib_address,matic_poly_address,tsuka_address,bank_name_gbp,bank_address_gbp,routing_aba_number_gbp,swift_bic_gbp,account_number_gbp,beneficiary_name_gbp,benefiary_address_gbp,intermediary_bank_gbp,intermediary_bank_address_gbp,intermediary_swift_bic_code_gbp,iban_gbp,refmemo_gbp} = this.state;
    if (loading) return <Loader />
   return (   <div className="main-content">
                      
        <Grid fluid>
       
          <Row>
          <Col lg={12}>  <h3>Banking/ Wallet Info</h3> </Col>
       <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>Banking/ Wallet Info</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}> 

                   
  

              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
              
                  content={        <div> <Tabs defaultActiveKey="usd" id="uncontrolled-tab-example">
                    <Tab eventKey="usd" title="USD Account">
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       bank name
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="bank_name"
                          value={bank_name}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.bank_nameError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       bank address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                           rows="5"
                           componentClass="textarea"
                           bsClass="form-control"
                            name="bank_address"
                          value={bank_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.bank_addressError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        routing aba number
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="routing_aba_number"
                          value={routing_aba_number}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.routing_aba_numberError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         swift bic
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="swift_bic"
                          value={swift_bic}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.swift_bicError}
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  transit number
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="transit"
    value={transit}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />

  </Col>
</FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         account number
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="account_number"
                          value={account_number}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.account_numberError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         beneficiary name
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="beneficiary_name"
                          value={beneficiary_name}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.beneficiary_nameError}
                        </Col>
                      </FormGroup>

                   
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         benefiary address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="benefiary_address"
                          value={benefiary_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.benefiary_addressError}
                        </Col>
                      </FormGroup>

                   


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         intermediary bank
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="intermediary_bank"
                          value={intermediary_bank}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.intermediary_bankError}
                        </Col>
                      </FormGroup>

                   
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         intermediary swift bic code
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="intermediary_swift_bic_code"
                          value={intermediary_swift_bic_code}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.intermediary_swift_bic_codeError}
                        </Col>
                      </FormGroup>

                   
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         intermediary bank address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="intermediary_bank_address"
                          value={intermediary_bank_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.intermediary_bank_addressError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Ref/Memo
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="refmemo_usd"
                          value={refmemo_usd}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.refmemo_usdError}
                        </Col>
                      </FormGroup>

                   
                      </Tab>
  <Tab eventKey="cad" title="CAD Account">

<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  bank name
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="bank_name_cad"
    value={bank_name_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.bank_name_cadError}
  </Col>
</FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       bank address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                              rows="5"
                              componentClass="textarea"
                              bsClass="form-control"
                            name="bank_address_cad"
                          value={bank_address_cad}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.bank_address_cadError}
                        </Col>
                      </FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  routing aba number
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="routing_aba_number_cad"
    value={routing_aba_number_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.routing_aba_number_cadError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  swift bic
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="swift_bic_cad"
    value={swift_bic_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.swift_bic_cadError}
  </Col>
</FormGroup>



<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  transit number
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="transit_cad"
    value={transit_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />

  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  institution number
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="institution_cad"
    value={institution_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
   
  </Col>
</FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  account number
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="account_number_cad"
    value={account_number_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.account_number_cadError}
  </Col>
</FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  beneficiary name
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="beneficiary_name_cad"
    value={beneficiary_name_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.beneficiary_name_cadError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  benefiary address
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="benefiary_address_cad"
    value={benefiary_address_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.benefiary_address_cadError}
  </Col>
</FormGroup>




<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  intermediary bank
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="intermediary_bank_cad"
    value={intermediary_bank_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.intermediary_bank_cadError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  intermediary swift bic code
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="intermediary_swift_bic_code_cad"
    value={intermediary_swift_bic_code_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.intermediary_swift_bic_code_cadError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  intermediary bank address
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="intermediary_bank_address_cad"
    value={intermediary_bank_address_cad}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.intermediary_bank_address_cadError}
  </Col>
</FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Ref/Memo
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="refmemo_cad"
                          value={refmemo_cad}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.refmemo_cadError}
                        </Col>
                      </FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  Etransfer Email
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="etransfer_email"
    value={etransfer_email}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
   
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
 Etransfer Password
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="etransfer_password"
    value={etransfer_password}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />

  </Col>
</FormGroup>



  </Tab>
  <Tab eventKey="eur" title="EUR Account" >
  

<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  bank name
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="bank_name_eur"
    value={bank_name_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.bank_name_eurError}
  </Col>
</FormGroup>



<FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       bank address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                              rows="5"
                              componentClass="textarea"
                              bsClass="form-control"
                            name="bank_address_eur"
                          value={bank_address_eur}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.bank_address_eurError}
                        </Col>
                      </FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  routing aba number
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="routing_aba_number_eur"
    value={routing_aba_number_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.routing_aba_number_eurError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  swift bic
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="swift_bic_eur"
    value={swift_bic_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.swift_bic_eurError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  account number
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="account_number_eur"
    value={account_number_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.account_number_eurError}
  </Col>
</FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  beneficiary name
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="beneficiary_name_eur"
    value={beneficiary_name_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.beneficiary_name_eurError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  benefiary address
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="benefiary_address_eur"
    value={benefiary_address_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.benefiary_address_eurError}
  </Col>
</FormGroup>




<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  intermediary bank
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="intermediary_bank_eur"
    value={intermediary_bank_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.intermediary_bank_eurError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  intermediary swift bic code
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="intermediary_swift_bic_code_eur"
    value={intermediary_swift_bic_code_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.intermediary_swift_bic_code_eurError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  intermediary bank address
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="intermediary_bank_address_eur"
    value={intermediary_bank_address_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />
     {this.state.intermediary_bank_address_eurError}
  </Col>
</FormGroup>


<FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  IBAN
  </Col>
  <Col sm={6}>
    <FormControl
      type="text"
      name="iban_eur"
    value={iban_eur}
      onBlur={this.handleValidate}
      onChange={this.handleChange}
    />

  </Col>
</FormGroup>

<FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Ref/Memo
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="refmemo_eur"
                          value={refmemo_eur}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.refmemo_eurError}
                        </Col>
                      </FormGroup>

</Tab>
<Tab eventKey="gbp" title="GBP Account" >
  

  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    bank name
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="bank_name_gbp"
      value={bank_name_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.bank_name_gbpError}
    </Col>
  </FormGroup>
  
  
  
  <FormGroup controlId="formHorizontalRequiredText">
                          <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         bank address
                          </Col>
                          <Col sm={6}>
                            <FormControl
                                rows="5"
                                componentClass="textarea"
                                bsClass="form-control"
                              name="bank_address_gbp"
                            value={bank_address_gbp}
                              onBlur={this.handleValidate}
                              onChange={this.handleChange}
                            />
                             {this.state.bank_address_gbpError}
                          </Col>
                        </FormGroup>
  
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    routing aba number
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="routing_aba_number_gbp"
      value={routing_aba_number_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.routing_aba_number_gbpError}
    </Col>
  </FormGroup>
  
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    swift bic
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="swift_bic_gbp"
      value={swift_bic_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.swift_bic_gbpError}
    </Col>
  </FormGroup>
  
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    account number
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="account_number_gbp"
      value={account_number_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.account_number_gbpError}
    </Col>
  </FormGroup>
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    beneficiary name
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="beneficiary_name_gbp"
      value={beneficiary_name_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.beneficiary_name_gbpError}
    </Col>
  </FormGroup>
  
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    benefiary address
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="benefiary_address_gbp"
      value={benefiary_address_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.benefiary_address_gbpError}
    </Col>
  </FormGroup>
  
  
  
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    intermediary bank
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="intermediary_bank_gbp"
      value={intermediary_bank_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.intermediary_bank_gbpError}
    </Col>
  </FormGroup>
  
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    intermediary swift bic code
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="intermediary_swift_bic_code_gbp"
      value={intermediary_swift_bic_code_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.intermediary_swift_bic_code_gbpError}
    </Col>
  </FormGroup>
  
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    intermediary bank address
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="intermediary_bank_address_gbp"
      value={intermediary_bank_address_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
       {this.state.intermediary_bank_address_gbpError}
    </Col>
  </FormGroup>
  
  
  <FormGroup controlId="formHorizontalRequiredText">
    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
    IBAN
    </Col>
    <Col sm={6}>
      <FormControl
        type="text"
        name="iban_gbp"
      value={iban_gbp}
        onBlur={this.handleValidate}
        onChange={this.handleChange}
      />
  
    </Col>
  </FormGroup>
  
  <FormGroup controlId="formHorizontalRequiredText">
                          <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                          Ref/Memo
                          </Col>
                          <Col sm={6}>
                            <FormControl
                              type="text"
                              name="refmemo_gbp"
                            value={refmemo_gbp}
                              onBlur={this.handleValidate}
                              onChange={this.handleChange}
                            />
                             {this.state.refmemo_gbpError}
                          </Col>
                        </FormGroup>
  
  </Tab>
<Tab eventKey="btc" title="Wallet Address">
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       BTC Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="btc_address"
                          value={btc_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       BCH Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="bch_address"
                          value={bch_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       LTC Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="ltc_address"
                          value={ltc_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       ETH Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="eth_address"
                          value={eth_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                      DOGE Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="doge_address"
                          value={doge_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                      XRP Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="xrp_address"
                          value={xrp_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
  <Col componentClass={ControlLabel} sm={2} smOffset={2}>
  XRP destination tag
  </Col>
  <Col sm={6}>
  <FormControl
                            type="text"
                            name="xrp_destination_tag"
                          value={xrp_destination_tag}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
  </Col>
</FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       USDT (ERC20) Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="usdt_address"
                          value={usdt_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       USDC (ERC20) Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="usdc_address"
                          value={usdc_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     SOL Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="sol_address"
                          value={sol_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                      DASH Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="dash_address"
                          value={dash_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                      USDT (TRON) Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="usdt_tron_address"
                          value={usdt_tron_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    BNB Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="bnb_address"
                          value={bnb_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    ADA Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="ada_address"
                          value={ada_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                   AVAX Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="avax_address"
                          value={avax_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    POLYGON (MATIC) Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="matic_poly_address"
                          value={matic_poly_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    SHIB (ERC20) Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="shib_address"
                          value={shib_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    MLP (ERC20) Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="mlp_address"
                          value={mlp_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    TSUKA (ERC20) Address
                        </Col>
                        <Col sm={6}> You must own and control this wallet address.
                          <FormControl
                            type="text"
                            name="tsuka_address"
                          value={tsuka_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                   
                        </Col>
                      </FormGroup>

                   
                      </Tab>
</Tabs>
</div>
                  }
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                      Save
                    </Button>
                  }
                />
              </Form>
            </Col>
                 
                  </Row>
               
        </Grid>

    <Modal
        backdrop='static'
        keyboard={false}
                      show={this.state.showModal}
                      onHide={() => this.setState({ showModal: false })}
                    ><Modal.Header closeButton>
                        <Modal.Title>Enter Verification Code</Modal.Title>
                      </Modal.Header> 
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col sm={12}> 
         
         <Form horizontal>

         {this.state.twofa_verify==false
              ?  <div>
                <FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Enter the verification code sent to your email address</Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
  Verification Code
  </Col> <Col componentClass={ControlLabel} sm={7} smOffset={0}>
  <FormControl
                            type="text"
                            name="verifycode"
                          value={this.state.verifycode}
                            onBlur={this.handleValidateCode}
                            onChange={this.handleChange}
                          /></Col></FormGroup>
                           <div>{this.state.verifycodeError}</div> 
</div>
            : 
            <div>
                <FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Enter your 2FA verification code from your app </Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
Verification Code
  </Col> <Col componentClass={ControlLabel} sm={7} smOffset={0}>
  <FormControl
                            type="text"
                            name="verifycode"
                          value={this.state.verifycode}
                            onBlur={this.handleValidateCode}
                            onChange={this.handleChange}
                          /></Col></FormGroup>
                           <div>{this.state.verifycodeError}</div> 
</div>
            }</Form>
         
         </Col></Row></Grid></Modal.Body>  <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showModal: false,showVerify:false,isSubmitted:false,verifycodeError:null })}
                        >Cancel
                        </Button>
                        <Button
                        disabled={isSubmitted}
                         simple
                         onClick={() => this.handleSubmitVerify()}
                         
                        >Verify
                        </Button>
                      </Modal.Footer></Modal>
      </div>
    );
  }
}

export default BankingInfo ;
