import React, { Component } from "react";

const path = require('path')
const Config = require('config');
var ps;
export class IconImage extends Component {


  constructor(props){
    super(props);
    this.state = {
        iconData:[],
        coin:this.props.coin,
        txid:this.props.txid,
        explorer_url:''
    }
    
  }
  
 

 componentDidMount(){
    var iconData=JSON.parse(localStorage.getItem('assets'));
    this.setState({iconData:iconData},()=>{
      //console.log(this.props.coin)
      this.geturl(this.props.coin)
  //  console.log(JSON.stringify(this.state.iconData))
    })
      }

     componentWillReceiveProps(nextProps) {
        this.setState({coin:nextProps.coin},()=>{
          this.geturl(this.props.coin)
      //  console.log(JSON.stringify(this.state.iconData))
        });
    }
    
 
 

      geturl(coin) {

        if(coin=='USD' || coin=='CAD' || coin=='EUR'  || coin=='GBP'){

        let explorer_url=''
          this.setState({explorer_url:explorer_url})}else{
        let icons = JSON.stringify(this.state.iconData.data);

       let explorer_url = JSON.parse(icons).filter(e => e.asset_id == coin).map(assetdata =>  assetdata.explorer_url ) 

        this.setState({explorer_url: explorer_url});}
      }


  
  render() {
    const {explorer_url,txid} = this.state;

    return (
     <a href={explorer_url+txid} target="_blank" >{txid}</a>

     
        
      
        
    )
  }
}

export default IconImage;
