import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Modal,
} from "react-bootstrap";



import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckboxv2.jsx";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');

var md5 = require('md5');

class AccountInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      error:false,
      loading: true,
      alert: null,
      newuser:false,
      show: false,

      usertype:0,
      active:0,
      activeval:false,
      usernamen:'',
      firstname:"",
      lastname:"",
      merchant_id:0,
      usernameError:null,
      firstnameError:null,
     lastnameError:null,
merchantAccounts:[],
      passwordError:null,
      merchant_idError:null,
      user_id:null,
      password:"",
      admin:0,
      adminval:false,
      pos:0,
      posval:false,
      update:false,
      showModal:false,
      showVerify:false,
      twofa_verify:false,
      isSubmitted:false,
      verifycode:"",
      verifycodeError:null,
     
     
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAdminChange = this.handleAdminChange.bind(this);
    this.handlePOSChange = this.handlePOSChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleValidate = this.handleValidate.bind(this);
    this.handleValidateCode = this.handleValidateCode.bind(this);
 
  }

  componentDidMount(){
 this.props.match.params.uid!='new'
   ? this.setState({user_id:this.props.match.params.uid},this.getuserinfo())
   : this.setState({loading: false,newuser:true})


  }


 async  updatestates(user){

    await this.setState({pos:user.data.pos,admin:user.data.madmin,usernamen:user.data.username,firstname:user.data.firstname,merchant_id:user.data.merchant_id,active:user.data.active,lastname:user.data.lastname,loading:false})
    if(this.state.admin==1){this.setState({adminval:true})}else{this.setState({adminval:false})}
    if(this.state.active==1){this.setState({activeval:true})}else{this.setState({activeval:false})}
    if(this.state.pos==1){this.setState({posval:true})}else{this.setState({posval:false})}
 

}
  getuserinfo(){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);

    const user_id=this.props.match.params.uid;
    var auid=user.data.user_id;
         var mid=user.data.merchant_id;
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':mid ,'auid': auid},
    };

    fetch(Config.API+'/api/users/'+user_id,requestOptions)
    .then( this.handleResponse)
      .then( user => this.updatestates(user))
  

  })
  
    }




    updateuserinfo(firstname,lastname,active,admin,user_id,pos){

     
      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
           var mid=user.data.merchant_id;
var token=user.token;
var auid=user.data.user_id;
var verifycode=this.state.verifycode
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid,'auid': auid},
         body: JSON.stringify({firstname,lastname,active,admin,user_id,pos,verifycode})
      };
         
    
  return fetch(Config.API+'/api/users/update',requestOptions)
  .then( this.handleRequestResponse)
  .then(user=>{
   if (user.status=='success') {
     this.setState({showModal:false, verifycode:""},
     this.props.handleClick("tr","success","User has been updated",<span data-notify='icon' className='pe-7s-check' />))
 
   } else{  this.setState({verifycodeError:(<small className="text-danger">{user.message}</small>)})}})
    
    })
  }
  
  saveuserinfo(username,password,firstname,lastname,active,admin,pos){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
var token=user.token;
var auid=user.data.user_id;
var verifycode=this.state.verifycode
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid,'auid': auid},
       body: JSON.stringify({ username,password,firstname,lastname,active,admin,pos,verifycode})
    };
        
  
return fetch(Config.API+`/api/users/create`,requestOptions)
.then( this.handleRequestResponse)
.then( user =>  {if (user.status=='success') {this.setState({ user_id:user.data.user_id},function(){
 if(this.state.user_id!=0){
   this.setState({newuser:false,update:true},function(){
     this.setState({showModal:false, verifycode:""},
   this.props.handleClick("tr","success","User has been created",<span data-notify='icon' className='pe-7s-check' />))
   })
 }else{
   this.setState({usernameError:(<small className="text-danger">User with this email already exists</small>)})
 }
})} else{ 
 if (user.data!=null){
   if (user.data==0){

     this.setState({showModal:false, verifycode:"",verifycodeError:"",usernameError:(<small className="text-danger">User with this email already exists</small>)})

   }else{
   this.setState({verifycodeError:(<small className="text-danger">{user.message}</small>)})}
 }else{  this.setState({verifycodeError:(<small className="text-danger">{user.message}</small>)})}
 
}})

  })
   
}

handleRequestResponse(response) {

  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          
       // const error = (data && data.message);
        return 'error'
      }else{

        if(data.status=='error'){
          if(data.type=='auth'){
         

return data
          }else if(data.data==0) {
            return data
          }else{

               userService.logout()
             return 'error'
          }
          
         

        }else{return data;}
      

      }
     

      
  });
}

handleCreateResponse(response) {

  return response.text().then(text => {
      const data = text && JSON.parse(text);

      return data;
  });
}
  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);

        // if (!response.ok) {
            
        //  // const error = (data && data.message);
        //   return 'error'
        // }else{
        //   console.log(data.status)
        //   if(data.status=='error'){
        //     userService.logout()
        //     window.location.reload(true);
        //        return 'error'
        //   }
        

        // }
       

        return data;
    });
}



    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value })

  }
  handleActiveChange(){
    this.state.activeval==true
    ? this.setState({activeval:false,active:0})
 : this.setState({activeval:true,active:1})
    
  }


  handlePOSChange(){
    this.state.posval==true
    ? this.setState({posval:false,pos:0})
 : this.setState({posval:true,pos:1})
    
  }

  handleAdminChange(){
    this.state.adminval==true
    ? this.setState({adminval:false,admin:0})
 : this.setState({adminval:true,admin:1})
    
  }

  handleMerchantSelectChange = (data)=> {
    if(data!==null){
    this.setState({ merchant_id:data.value});}
   
  };

  handleUsertypeSelectChange = (data)=> {
    if(data!==null){
  data.value===0
  ?this.setState({ usertype:data.value})
   : this.setState({ usertype:data.value,merchant_id:0})
    }
  };



  handleValidateCode(){


    this.state.verifycode.length<= 5
    ? this.setState({
      verifycodeError: (
        <small className="text-danger"></small>
        ),verifydisable:true
    })
        : isNaN(this.state.verifycode)
        ?  this.setState({
          verifycodeError: (
          <small className="text-danger">Code must be numeric</small>
          ),verifydisable:true
          })
        : this.setState({ verifycodeError: null ,verifydisable:false,isSubmitted:false});
  
  }

   
  handleValidate(e) {


    this.state.password.length< 8
? this.setState({
passwordError: (
<small className="text-danger">Password must be at least 8 characters long</small>
)
})
: this.state.password.search(/\d/) == -1
? this.setState({
 passwordError: (
     <small className="text-danger">Password must contain 1 number</small>
   )
     })
     : this.state.password.search(/[a-zA-Z]/) == -1
? this.setState({
 passwordError: (
     <small className="text-danger">Password must contain 1 letter</small>
   )
     })
    : this.setState({ passwordError: null });


    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(this.state.usernamen) === false
      ? this.setState({
       usernameError: (
            <small className="text-danger">
              This must be a valid email address.
            </small>
          )
        })
      : this.setState({ usernameError: null });


        this.state.firstname==""
        ? this.setState({
        firstnameError: (
             <small className="text-danger">this field is required</small>
           )
             })
            : this.setState({ firstnameError: null });

            
            this.state.lastname==""
            ? this.setState({
             lastnameError: (
                 <small className="text-danger">this field is required</small>
               )
                 })
                : this.setState({ lastnameError: null });

               

               
               

                    return
}

handleSubmit(e) {
  e.preventDefault();





  if(this.state.newuser){if(this.state.passwordError === null  && this.state.firstnameError === null && this.state.lastnameError === null && this.state.usernameError === null){
    this.setState({showVerify:true,showModal:true,verifycode:""},function(){
      this.request2fa()
      })
  
  }}else{
      
if(this.state.firstnameError === null && this.state.lastnameError === null ){
this.setState({showVerify:true,showModal:true,verifycode:""},function(){
  this.request2fa()
  })}}

 }


   handleSubmitVerify() {

    const { usernamen,password,firstname,lastname,active,admin,user_id,pos} = this.state;



    if(this.state.newuser){if( this.state.firstnameError === null && this.state.lastnameError === null && this.state.usernameError === null){
 
      this.saveuserinfo(usernamen,md5(password),firstname,lastname,active,admin,pos)
    
    }}else{
        
 if(this.state.firstnameError === null && this.state.lastnameError === null ){

  this.updateuserinfo(firstname,lastname,active,admin,user_id,pos)}}

   }
   request2fa(){

    userService.getinfo().then((data)=>{
  
      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var token=user.token;
    var auid=user.data.user_id;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid,'auid': auid}
  };
  
  fetch(Config.API+`/api/authenticate`, requestOptions)
    .then(this.handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
  this.setState({  twofa_verify:user.data.twofa_enabled})

          
      }
    })
  
  })
  }

   render() {
  
    
    
    const {isSubmitted,lastname,firstname,usernamen,password,pos,merchant_id,adminval,activeval,posval,merchantAccounts,newuser} = this.state; 


if (this.state.loading) return <Loader />
      return (
      <div className="main-content">
                      
        <Grid fluid>
        <Breadcrumb>
            <Breadcrumb.Item href="/#/users">Users</Breadcrumb.Item>
            <Breadcrumb.Item active>User Info</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
         
       
                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
                  title="User Profile"
                  content={
                    <div>
              
           


              <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Email/Username
                        </Col>
                        <Col  sm={6}>
                        {  newuser==true
                        ?  <FormControl
                            type="text"
                            name="usernamen"
                          value={usernamen}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          : <div className="textdisplay" >{this.state.usernamen}</div>

                    
                        } {this.state.usernameError}
                        </Col>
                      </FormGroup>


        <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         First Name
                        </Col>
                        <Col sm={6}>
                        <FormControl
                            type="text"
                            name="firstname"
                          value={firstname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.firstnameError}
                    

                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Last Name
                        </Col>
                        <Col  sm={6}>
                        <FormControl
                            type="text"
                            name="lastname"
                          value={lastname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.lastnameError}
                        </Col>
                      </FormGroup>
                      {  newuser==true
                     ? <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     Password
                        </Col>
                        <Col sm={6}>
                        <FormControl
                            type="text"
                            name="password"
                          value={password}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.passwordError}
                        </Col>
                      </FormGroup>
                      : <div/>}
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                  POS Access
                        </Col>
                        <Col sm={6}>
                          
                     

                        <div className="checkbox checkbox-inline">
                        <Checkbox
                        isChecked={posval}
                        id="3"
                   
                        onChange={this.handlePOSChange}
                      />
      </div>
                 


                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                  Admin Access
                        </Col>
                        <Col sm={6}>
                          
                     

                        <div className="checkbox checkbox-inline">
                        <Checkbox
                        isChecked={adminval}
                        id="2"
                   
                        onChange={this.handleAdminChange}
                      />
      </div>
                 


                        </Col>
                      </FormGroup>
                  
                  
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                  Active
                        </Col>
                        <Col sm={6}>
                          
                     

                        <div className="checkbox checkbox-inline">
                        <Checkbox
                        isChecked={activeval}
                        id="1"
                   
                        onChange={this.handleActiveChange}
                      />
                 
      </div>
                 


                        </Col>
                      </FormGroup>
                     
                    </div>
                  }
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                    
                      onClick={this.handleValidate}
                    >
                      Save
                    </Button>
                    
                  }
      
                />
              </Form>
            </Col>
                 
                  </Row>
               
        </Grid>

    <Modal
        backdrop='static'
        keyboard={false}
                      show={this.state.showModal}
                      onHide={() => this.setState({ showModal: false })}
                    ><Modal.Header closeButton>
                        <Modal.Title>Enter Verification Code</Modal.Title>
                      </Modal.Header> 
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col sm={12}> 
         
         <Form horizontal>

         {this.state.twofa_verify==false
              ?  <div>
                <FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Enter the verification code sent to your email address</Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
  Verification Code
  </Col> <Col componentClass={ControlLabel} sm={7} smOffset={0}>
  <FormControl
                            type="text"
                            name="verifycode"
                          value={this.state.verifycode}
                            onBlur={this.handleValidateCode}
                            onChange={this.handleChange}
                          /></Col></FormGroup>
                           <div>{this.state.verifycodeError}</div> 
</div>
            : 
            <div>
                <FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Enter your 2FA verification code from your app </Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
Verification Code
  </Col> <Col componentClass={ControlLabel} sm={7} smOffset={0}>
  <FormControl
                            type="text"
                            name="verifycode"
                          value={this.state.verifycode}
                            onBlur={this.handleValidateCode}
                            onChange={this.handleChange}
                          /></Col></FormGroup>
                           <div>{this.state.verifycodeError}</div> 
</div>
            }</Form>
         
         </Col></Row></Grid></Modal.Body>  <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showModal: false,showVerify:false,isSubmitted:false ,verifycodeError:null})}
                        >Cancel
                        </Button>
                        <Button
                        disabled={isSubmitted}
                         simple
                         onClick={() => this.handleSubmitVerify()}
                         
                        >Verify
                        </Button>
                      </Modal.Footer></Modal>
      </div>
    );
  }
}

export default AccountInfo ;
