import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckboxv2.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class AssetSettings extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      merchant_id:null,
      btc_limit:0,
      bch_limit:0,
      ltc_limit:0,
      eth_limit:0,
      doge_limit:0,
      usdt_limit:0,
      usdc_limit:0,
      xrp_limit:0,
      usdt_tron_limit:0,
      sol_limit:0,
      dash_limit:0,
      bnb_limit:0,
      avax_limit:0,
      shib_limit:0,
      ada_limit:0,
      matic_poly_limit:0,
      tsuka_limit:0,
      mlp_limit:0,
      erc20fee:0,
      ethper:0,
      merchant_fee_percent:0,
      merchant_fee_eth_percent:0,
      Page_Error:null,
      btcError: null,
      bchError: null,
      ltcError: null,
      ethError: null,
      dogeError: null,
      usdtError: null,
      usdcError: null,
      xrpError: null,
      usdt_tronError: null,
      solError: null,
      dashError: null,
      bnbError: null,
      adaError: null,
      avaxError: null,
      mlpError: null,
      tsukaError: null,
      matic_polyError: null,
      shibError: null,
      showModal:false,
      showVerify:false,
      twofa_verify:false,
      isSubmitted:false,
      verifycode:"",
      verifycodeError:null,
     
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleValidateCode = this.handleValidateCode.bind(this);
  }

  componentWillMount(){
    this.getassetlimits();
    


  }



  getassetlimits(){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'access-token': user.token ,'mid':user.data.merchant_id,'auid': auid},
    };

    fetch(Config.API+'/api/assetlimits',requestOptions)
    .then( this.handleResponse)
      .then( assetslimit => this.setState({
        btc_limit:assetslimit.data.btc_limit,
        bch_limit:assetslimit.data.bch_limit,
        eth_limit:assetslimit.data.eth_limit,
        doge_limit:assetslimit.data.doge_limit,
        ltc_limit:assetslimit.data.ltc_limit,
        usdt_limit:assetslimit.data.usdt_limit,
        usdc_limit:assetslimit.data.usdc_limit,
        xrp_limit:assetslimit.data.xrp_limit,
        usdt_tron_limit:assetslimit.data.usdt_tron_limit,
        sol_limit:assetslimit.data.sol_limit,
        dash_limit:assetslimit.data.dash_limit,
        bnb_limit:assetslimit.data.bnb_limit,
        ada_limit:assetslimit.data.ada_limit,
        avax_limit:assetslimit.data.avax_limit,
        shib_limit:assetslimit.data.shib_limit,
        mlp_limit:assetslimit.data.mlp_limit,
        matic_poly_limit:assetslimit.data.matic_poly_limit,
        tsuka_limit:assetslimit.data.tsuka_limit,
         loading: false},function(){
          this.getmerchantinfo()
         }));

     
        })
    }


  getmerchantinfo(){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'access-token': user.token ,'mid':user.data.merchant_id,'auid': auid},
    };

    fetch(Config.API+'/api/merchant',requestOptions)
    .then( this.handleResponse)
      .then( merchant => this.setState({erc20fee:merchant.erc20fee,ethper:merchant.ethper,merchant_fee_percent:merchant.data.client_fee_percent,merchant_fee_eth_percent:merchant.data.client_fee_eth_percent}));

     
  })
    }

    handleRequestResponse(response) {

      return response.text().then(text => {
          const data = text && JSON.parse(text);
    
          if (!response.ok) {
              
           // const error = (data && data.message);
            return 'error'
          }else{
    
            if(data.status=='error'){
              if(data.type=='auth'){
             
    
    return data
              }else if(data.data.user_id==0) {
                return data
              }else{
    
                   userService.logout()
                 return 'error'
              }
              
             
    
            }else{return data;}
          
    
          }
         
    
          
      });
    }
      handleCreateResponse(response) {
    
        return response.text().then(text => {
            const data = text && JSON.parse(text);
      
            return data;
        });
      }
        handleResponse(response) {
      
          return response.text().then(text => {
              const data = text && JSON.parse(text);
       
              if (!response.ok) {
                  
               // const error = (data && data.message);
                return 'error'
              }else{
              //  console.log(data.status)
                if(data.status=='error'){
                  userService.logout()
                  
                     return 'error'
                }
              
      
              }
             
      
              return data;
          });
      }
    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }



  
  handleValidateCode(){


    this.state.verifycode.length<= 5
    ? this.setState({
      verifycodeError: (
        <small className="text-danger"></small>
        ),verifydisable:true
    })
        : isNaN(this.state.verifycode)
        ?  this.setState({
          verifycodeError: (
          <small className="text-danger">Code must be numeric</small>
          ),verifydisable:true
          })
        : this.setState({ verifycodeError: null ,verifydisable:false,isSubmitted:false});
  
  }
  handleValidate(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  
    isNaN(this.state.btc_limit)
    ? this.setState({
      btcError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.btc_limit < 0
    ? this.setState({
     btcError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ bchError: null });


    isNaN(this.state.bch_limit)
    ? this.setState({
      bchError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.bch_limit < 0
    ? this.setState({
     bchError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ bchError: null });

    isNaN(this.state.eth_limit)
    ? this.setState({
     ethError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.eth_limit < 0
    ? this.setState({
     ethError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ ethError: null });

    isNaN(this.state.ltc_limit)
    ? this.setState({
     ltcError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.ltc_limit < 0
    ? this.setState({
    ltcError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ ltcError: null });


    isNaN(this.state.doge_limit)
    ? this.setState({
     dogeError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.doge_limit < 0
    ? this.setState({
    dogeError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ dogeError: null });

    isNaN(this.state.usdt_limit)
    ? this.setState({
     usdtError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.usdt_limit < 0
    ? this.setState({
    usdtError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ usdtError: null });
    

    isNaN(this.state.usdc_limit)
    ? this.setState({
     usdcError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.usdc_limit < 0
    ? this.setState({
    usdcError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ usdcError: null });


    isNaN(this.state.xrp_limit)
    ? this.setState({
     xrpError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.xrp_limit < 0
    ? this.setState({
 xrpError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ xrpError: null });

    isNaN(this.state.dash_limit)
    ? this.setState({
     dashError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.dash_limit < 0
    ? this.setState({
 dashError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ dashError: null });


    isNaN(this.state.sol_limit)
    ? this.setState({
     solError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.sol_limit < 0
    ? this.setState({
 solError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ solError: null });


    isNaN(this.state.usdt_tron_limit)
    ? this.setState({
     usdt_tronError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.usdt_tron_limit < 0
    ? this.setState({
      usdt_tronError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ usdt_tronError: null });

    isNaN(this.state.bnb_limit)
    ? this.setState({
     bnbError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.bnb_limit < 0
    ? this.setState({
      bnbError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ bnbError: null });
 
    


    isNaN(this.state.ada_limit)
    ? this.setState({
      adaError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.ada_limit < 0
    ? this.setState({
      adaError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ adaError: null });



    isNaN(this.state.avax_limit)
    ? this.setState({
      avaxError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.avax_limit < 0
    ? this.setState({
      avaxError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ avaxError: null });



    isNaN(this.state.shib_limit)
    ? this.setState({
      shibError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.shib_limit < 0
    ? this.setState({
      shibError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ shibError: null });



    isNaN(this.state.matic_poly_limit)
    ? this.setState({
      matic_polyError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.bnb_limit < 0
    ? this.setState({
      matic_polyError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ matic_polyError: null });



    isNaN(this.state.mlp_limit)
    ? this.setState({
      mlpError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.mlp_limit < 0
    ? this.setState({
      mlpError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ mlpError: null });


    isNaN(this.state.tsuka_limit)
    ? this.setState({
      tsukaError: (
          <small className="text-danger">Must be a number </small>
        )
         })
    : this.state.tsuka_limit < 0
    ? this.setState({
      tsukaError: (
          <small className="text-danger">Minimum is 0</small>
        )
         })
    :this.setState({ tsukaError: null });
  
  }



  updateassetlimits(btc_limit,bch_limit,ltc_limit,eth_limit,doge_limit,usdt_limit,usdc_limit,xrp_limit,sol_limit,dash_limit,usdt_tron_limit,bnb_limit,ada_limit,avax_limit,shib_limit,mlp_limit,matic_poly_limit,tsuka_limit){
    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var auid=user.data.user_id;
    var verifycode=this.state.verifycode
var token=user.token;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id,'auid': auid},
       body: JSON.stringify({btc_limit,bch_limit,ltc_limit,eth_limit,doge_limit,usdt_limit,usdc_limit,xrp_limit,sol_limit,dash_limit,usdt_tron_limit,bnb_limit,ada_limit,avax_limit,shib_limit,mlp_limit,matic_poly_limit,tsuka_limit,verifycode})
    };
        
  
return fetch(Config.API+`/api/assetlimits/update`,requestOptions)
.then( this.handleRequestResponse)
.then( user =>  {if (user.status=='success') {
  this.setState({showModal:false, verifycode:""},
this.props.handleClick("tr","success","Account asset limits have been updated",<span data-notify='icon' className='pe-7s-check' />))
} else{  this.setState({verifycodeError:(<small className="text-danger">{user.message}</small>)})}
  })
  
})}

request2fa(){

  userService.getinfo().then((data)=>{

    var user=JSON.parse(data);
  var mid=user.data.merchant_id;
  var token=user.token;
  var auid=user.data.user_id;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid,'auid': auid}
};

fetch(Config.API+`/api/authenticate`, requestOptions)
  .then(this.handleResponse)
  .then(user => {
    // login successful if there's a user in the response
    if (user) {
this.setState({  twofa_verify:user.data.twofa_enabled})

        
    }
  })

})
}
handleSubmit(e) {
  e.preventDefault();
  this.setState({showVerify:true,showModal:true,verifycode:""},function(){
    this.request2fa()
    })
 }

handleSubmitVerify() {
    const { btc_limit,bch_limit,ltc_limit,eth_limit,doge_limit,usdt_limit,usdc_limit,xrp_limit,sol_limit,dash_limit,usdt_tron_limit,bnb_limit,ada_limit,avax_limit,shib_limit,mlp_limit,matic_poly_limit,tsuka_limit} = this.state;
   if(  this.state.btcError  === null ||   this.state.bchError  === null ||   this.state.ltcError  === null ||   this.state.ethError  === null ||   this.state.dogeError  === null ||   this.state.usdtError  === null ||   this.state.usdcError  === null ||   this.state.usdt_tronError  === null ||   this.state.solError  === null ||   this.state.dashError  === null ||   this.state.xrpError  === null  ||   this.state.bnbError  === null  ||   this.state.solError  === null ||   this.state.adaError  === null ||   this.state.avaxError  === null ||   this.state.shibError  === null ||   this.state.matic_polyError  === null ||   this.state.mlpError  === null ||   this.state.tsukaError  === null ){
  this.updateassetlimits(btc_limit,bch_limit,ltc_limit,eth_limit,doge_limit,usdt_limit,usdc_limit,xrp_limit,sol_limit,dash_limit,usdt_tron_limit,bnb_limit,ada_limit,avax_limit,shib_limit,mlp_limit,matic_poly_limit,tsuka_limit)}

   }




  render() {
    const {isSubmitted, merchant_id,loading,btc_limit,bch_limit,ltc_limit,eth_limit,doge_limit,usdt_limit,usdc_limit,xrp_limit,sol_limit,dash_limit,usdt_tron_limit,bnb_limit,ada_limit,avax_limit,shib_limit,mlp_limit,matic_poly_limit,tsuka_limit,merchant_fee_percent,merchant_fee_eth_percent,erc20fee} = this.state;
    if (loading) return <Loader />
     return (
      <div className="main-content">


 <Grid fluid>
   
          <Row>
        
        <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>Asset Limits</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}>  <h3>Asset Limits</h3>
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
            
                  content={
                    <div>
                            <Col lg={12}>  
                            <div className="content"><p className="lgrey">The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS<br/> To never accept this asset enter 250000 as the amount in the corresponding fields</p></div>
                            </Col> 
                            <Col lg={12}> </Col>
                            <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     BTC <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}

                  
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="btc_limit"
                          value={btc_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.btcError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     ETH <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="eth_limit"
                          value={eth_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.ethError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     BCH <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="bch_limit"
                          value={bch_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.bchError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    LTC <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="ltc_limit"
                          value={ltc_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.ltcError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    DOGE <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="doge_limit"
                          value={doge_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.dogeError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                   USDT (ERC20) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="usdt_limit"
                          value={usdt_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.usdtError}
                        </Col>
                      </FormGroup>
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                   USDC (ERC20) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="usdc_limit"
                          value={usdc_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.usdcError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                   XRP <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="xrp_limit"
                          value={xrp_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.xrpError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                 DASH <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="dash_limit"
                          value={dash_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.dashError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     SOL <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="sol_limit"
                          value={sol_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.solError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     USDT (TRON)  <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="usdt_tron_limit"
                          value={usdt_tron_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.usdt_tronError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    BNB  <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="bnb_limit"
                          value={bnb_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.bnbError}
                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    MATIC (POLYGON) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="matic_poly_limit"
                          value={matic_poly_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.matic_polyError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    ADA  <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="ada_limit"
                          value={ada_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.adaError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    AVAX  <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="avax_limit"
                          value={avax_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.avaxError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    SHIB (ERC20)  <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="shib_limit"
                          value={shib_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.shibError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    TSUKA (ERC20) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="tsuka_limit"
                          value={tsuka_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.tsukaError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    MLP (ERC20)  <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
      The minimum amount needed in ($,€,£) to accept the assets in the widget/ invoice/ POS
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={2}>
                          <FormControl
                            type="text"
                            name="mlp_limit"
                          value={mlp_limit}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.mlpError}
                        </Col>
                      </FormGroup>



</div>}


               
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                      Save
                    </Button>
                  }
                />
              </Form>
            </Col>


            <Col lg={12}>  <h3>Fees</h3> </Col> 
            <Col md={12}> 
              <Form horizontal >
                <Card
            
                  content={
                    <div>
                      
                        
                        <FormGroup >     <Col  sm={2} smOffset={2}>
                        Fees
                        </Col>
                        <Col sm={6}>
                        <div  className="textdisplay">{merchant_fee_percent*100}%</div>
                        </Col></FormGroup>
                        <FormGroup >   <Col  sm={2} smOffset={2}>
                        ETH Fees
                        </Col>
                        <Col sm={6}>
                        <div  className="textdisplay">{merchant_fee_eth_percent*100}%</div>
                         

                
                        </Col>
                        </FormGroup>
                        <FormGroup >   <Col  sm={2} smOffset={2}>
                        ERC20 Fees 
                        </Col>
                        <Col sm={6}>
                        <div  className="textdisplay">{merchant_fee_percent*100}% + ${erc20fee}</div>
                         

                
                        </Col>
                        </FormGroup>
                     
                         
                   
                    
                    </div>
                  }
      
               
                />
              </Form>
            </Col>
           
                 
                  </Row>
               
        </Grid>
        <Modal
        backdrop='static'
        keyboard={false}
                      show={this.state.showModal}
                      onHide={() => this.setState({ showModal: false })}
                    ><Modal.Header closeButton>
                        <Modal.Title>Enter Verification Code</Modal.Title>
                      </Modal.Header> 
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col sm={12}> 
         
         <Form horizontal>

         {this.state.twofa_verify==false
              ?  <div>
                <FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Enter the verification code sent to your email address</Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
  Verification Code
  </Col> <Col componentClass={ControlLabel} sm={7} smOffset={0}>
  <FormControl
                            type="text"
                            name="verifycode"
                          value={this.state.verifycode}
                            onBlur={this.handleValidateCode}
                            onChange={this.handleChange}
                          /></Col></FormGroup>
                           <div>{this.state.verifycodeError}</div> 
</div>
            : 
            <div>
                <FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Enter your 2FA verification code from your app </Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
Verification Code
  </Col> <Col componentClass={ControlLabel} sm={7} smOffset={0}>
  <FormControl
                            type="text"
                            name="verifycode"
                          value={this.state.verifycode}
                            onBlur={this.handleValidateCode}
                            onChange={this.handleChange}
                          /></Col></FormGroup>
                           <div>{this.state.verifycodeError}</div> 
</div>
            }</Form>
         
         </Col></Row></Grid></Modal.Body>  <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showModal: false,showVerify:false,isSubmitted:false,verifycodeError:null  })}
                        >Cancel
                        </Button>
                        <Button
                        disabled={isSubmitted}
                         simple
                         onClick={() => this.handleSubmitVerify()}
                         
                        >Verify
                        </Button>
                      </Modal.Footer></Modal>
      </div>
  
    );
  }
}

export default AssetSettings ;
