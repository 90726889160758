import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckboxv2.jsx";
import Select from "react-select";
import { userService } from 'helpers/user.service.jsx';
const Config = require('config');


class Webhooks extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      merchant_id:null,
 description:"",
 pos_url:"",
 widget_url:"",
 manual_url:"",
 pos_urlError: null,
 manual_urlError: null,
 widget_urlError: null,
 pos_weburl: Config.POSUrl,
 showModal:false,
 showVerify:false,
 twofa_verify:false,
 isSubmitted:false,
 verifycode:"",
 verifycodeError:null,



      
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleValidateCode = this.handleValidateCode.bind(this);

 
  }
  componentDidMount(){

    this.getposinfo();


  }

  getposinfo(){

    userService.getinfo().then((data)=>{

      var user=JSON.parse(data);
      var auid=user.data.user_id;
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':user.data.merchant_id ,'auid': auid},
    };

    fetch(Config.API+'/api/webhooks/',requestOptions)
    .then( this.handleResponse)
     .then( webhooks=> this.setState({
        pos_url:webhooks.data.pos_url,
        widget_url:webhooks.data.widget_url,
        manual_url:webhooks.data.manual_url,
         loading: false},()=>{
         }));

        }) 
  
    }


    updatWebhookinfo(pos_url,widget_url,manual_url){

      userService.getinfo().then((data)=>{

        var user=JSON.parse(data);
    
   
      var token=user.token;
      var auid=user.data.user_id;
      var verifycode=this.state.verifycode
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','access-token': token,'mid':user.data.merchant_id,'auid': auid},
         body: JSON.stringify({ pos_url,widget_url,manual_url,verifycode}
          )
      };
          
    
  return fetch(Config.API+`/api/webhooks/update`,requestOptions)
   
  .then(this.handleRequestResponse)
   .then( user =>  {if (user.status=='success') {
    this.setState({showModal:false, verifycode:""},
  this.props.handleClick("tr","success","Webhooks info has been updated",<span data-notify='icon' className='pe-7s-check' />))
} else{  this.setState({verifycodeError:(<small className="text-danger">{user.message}</small>)})}
    })
  })}

  handleRequestResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
  
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
  
          if(data.status=='error'){
            if(data.type=='auth'){
           
  
  return data
            }else if(data.data.user_id==0) {
              return data
            }else{
  
                 userService.logout()
               return 'error'
            }
            
           
  
          }else{return data;}
        
  
        }
       
  
        
    });
  }
    handleCreateResponse(response) {
  
      return response.text().then(text => {
          const data = text && JSON.parse(text);
    
          return data;
      });
    }
      handleResponse(response) {
    
        return response.text().then(text => {
            const data = text && JSON.parse(text);
     
            if (!response.ok) {
                
             // const error = (data && data.message);
              return 'error'
            }else{
            //  console.log(data.status)
              if(data.status=='error'){
                userService.logout()
                
                   return 'error'
              }
            
    
            }
           
    
            return data;
        });
    }
  

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }



  
  handleValidateCode(){


    this.state.verifycode.length<= 5
    ? this.setState({
      verifycodeError: (
        <small className="text-danger"></small>
        ),verifydisable:true
    })
        : isNaN(this.state.verifycode)
        ?  this.setState({
          verifycodeError: (
          <small className="text-danger">Code must be numeric</small>
          ),verifydisable:true
          })
        : this.setState({ verifycodeError: null ,verifydisable:false,isSubmitted:false});
  
  }



  handleValidate(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    
    

      if(this.state.pos_url!=='')
    
      { if(!this.state.pos_url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
        pos_urlError: (
      <small className="text-danger">Please enter a valid URL</small>
      )
      })}else{this.setState({  pos_urlError: null })}
     
    }else{this.setState({  pos_urlError: null })}


    if(this.state.manual_url!=='')
    
    { if(!this.state.manual_url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
      manual_urlError: (
    <small className="text-danger">Please enter a valid URL</small>
    )
    })}else{this.setState({  manual_urlError: null })}
   
  }else{this.setState({  manual_urlError: null })}

  if(this.state.widget_url!=='')
    
  { if(!this.state.widget_url.match(/^(http|https):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]+([\-\.]{1}[-a-zA-Z0-9+&@#/%?=~_|]+)*\.[a-zA-Z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)){this.setState({
    widget_urlError: (
  <small className="text-danger">Please enter a valid URL</small>
  )
  })}else{this.setState({  widget_urlError: null })}
 
}else{this.setState({  widget_urlError: null })}


  
    
          


  }

  request2fa(){

    userService.getinfo().then((data)=>{
  
      var user=JSON.parse(data);
    var mid=user.data.merchant_id;
    var token=user.token;
    var auid=user.data.user_id;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':mid,'auid': auid}
  };
  
  fetch(Config.API+`/api/authenticate`, requestOptions)
    .then(this.handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
  this.setState({  twofa_verify:user.data.twofa_enabled})

          
      }
    })
  
  })
  }
  handleSubmit(e) {
    e.preventDefault();
    this.setState({showVerify:true,showModal:true,verifycode:""},function(){
      this.request2fa()
      })
   }

  handleSubmitVerify() {
  
    const { pos_url,widget_url,manual_url,pos_weburl} = this.state;
   if(  this.state.pos_urlError  === null && this.state.manual_urlError  === null &&  this.state.widget_urlError  === null  ){

  this.updatWebhookinfo(pos_url,widget_url,manual_url)}

   }



  render() {
    const { isSubmitted,widget_url,pos_url,manual_url,loading,pos_weburl} = this.state;
   
    if (loading) return <Loader />
   return (   <div className="main-content">
                      
        <Grid fluid>
       
          <Row>
          <Col lg={12}>  <h3>Webhook Settings</h3> </Col>
       <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
        
          </Breadcrumb>



  
         

                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
                
                  content={
                    <div>
                     

                


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                    Address Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="manual_url"
                          value={manual_url}
                          placeholder="https://yourwebhookurl.com"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.manual_urlError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     Widget Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="widget_url"
                          value={widget_url}
                          placeholder="https://yourwebhookurl.com"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.widget_urlError}
                        </Col>
                      </FormGroup>

{ pos_weburl!=''
?                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       POS Webhook URL (IPN) <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
        Notifications will be sent to the provided url.
        This field is optional
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="pos_url"
                          value={pos_url}
                          placeholder="https://yourwebhookurl.com"
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.pos_urlError}
                        </Col>
                      </FormGroup>
:''}
                  
                    
                    
              

                   
                    
                    </div>
                  }
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                     Update
                    </Button>
                  }
                />
              </Form>
            </Col>
 
         
                 
                  </Row>
               
        </Grid> <Modal
        backdrop='static'
        keyboard={false}
                      show={this.state.showModal}
                      onHide={() => this.setState({ showModal: false })}
                    ><Modal.Header closeButton>
                        <Modal.Title>Enter Verification Code</Modal.Title>
                      </Modal.Header> 
                      <Modal.Body>
                      <Grid fluid>
                      <Row>
         
       
         <Col sm={12}> 
         
         <Form horizontal>

         {this.state.twofa_verify==false
              ?  <div>
                <FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Enter the verification code sent to your email address</Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
  Verification Code
  </Col> <Col componentClass={ControlLabel} sm={7} smOffset={0}>
  <FormControl
                            type="text"
                            name="verifycode"
                          value={this.state.verifycode}
                            onBlur={this.handleValidateCode}
                            onChange={this.handleChange}
                          /></Col></FormGroup>
                           <div>{this.state.verifycodeError}</div> 
</div>
            : 
            <div>
                <FormGroup controlId="formHorizontalRequiredText">
<Col  sm={12}>
Enter your 2FA verification code from your app </Col>
  <Col componentClass={ControlLabel} sm={5} smOffset={0}>
Verification Code
  </Col> <Col componentClass={ControlLabel} sm={7} smOffset={0}>
  <FormControl
                            type="text"
                            name="verifycode"
                          value={this.state.verifycode}
                            onBlur={this.handleValidateCode}
                            onChange={this.handleChange}
                          /></Col></FormGroup>
                           <div>{this.state.verifycodeError}</div> 
</div>
            }</Form>
         
         </Col></Row></Grid></Modal.Body>  <Modal.Footer>
                        <Button
                          simple
                          onClick={() => this.setState({ showModal: false,showVerify:false,isSubmitted:false,verifycodeError:null })}
                        >Cancel
                        </Button>
                        <Button
                        disabled={isSubmitted}
                         simple
                         onClick={() => this.handleSubmitVerify()}
                         
                        >Verify
                        </Button>
                      </Modal.Footer></Modal>
      </div>
    );
  }
}

export default Webhooks ;
